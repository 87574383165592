.about {
  max-width: 100%;
  max-height: 100vh;
}

.about__container {
  display: flex;
}

.about_content__container {
  width: auto;
  padding: 1rem;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.2);
}

.about__header {
  font-style: normal;
  font-weight: 400;
  font-size: 5em;
  line-height: normal;
  padding-bottom: 2rem;
}

.about .sub__header {
  font-size: 2em;
  padding-bottom: 1rem;
}

.about__content {
  justify-content: space-between;
  font-weight: bold;
  flex-direction: column;
}

.image__container {
  display: flex;
  align-items: center;
  max-width: 100%;
  margin-left: 5rem;
}

.about__content p {
  width: 100%;
  line-height: 25px;
  font-size: 1.2em;
  padding-bottom: 2rem;
  font-weight: normal;
}

@media screen and (max-width: 1401px) {
  .about__header {
    font-size: 2rem; 
  }
}


@media screen and (max-width: 1201px) {
  .about__container {
    display: flex;
    flex-wrap: nowrap;
  }

  .about_content__container {
    max-width: 50%;
  }

  .about__header {
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    padding-bottom: 1rem;
  }

  .sub__header {
    font-size: 1rem;
    padding-bottom: 2rem;
  }

  .about__content {
    justify-content: space-between;
    font-weight: bold;
    flex-direction: column;
  }


  .about__content p {
    width: 100%;
    font-size: 1rem;
    padding-bottom: 1rem;
  }
  .image__container {
    max-width: 80% !important;
    height: auto;
    margin: 0 auto;
    align-items: flex-start;
  }
  .image__container img {
    transform: scale(0.9);
    height: auto;
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .about__header {
    font-size: 1.57rem;
  }
  .about .sub__header {
    font-size: 1rem;
    padding-bottom: 0rem;
  }
  .image__container {
    max-width: 60%;
  }
}

/*Medium Screens and Devices (e.g.:TABLETS)*/
@media screen and (max-width: 768px) {
  .about {
    max-width: 100%;
    max-height: 100%;
    
  }

  .about__container {
    display: flex;
    flex-direction: column;
  }

  .about_content__container {
    width: 100%;
    margin-right: 2rem;
    max-width: 100%;
  }

  .about__header {
    font-size: 4rem;
    padding-bottom: 1rem;
    line-height: normal;
    padding-bottom: 1rem;
  }

  .about .sub__header {
    font-size: 1rem;
    padding-bottom: 1rem;
  }

  .about__content {
    justify-content: space-between;
    font-weight: bold;
    flex-direction: column;
  }

  .image__container {
    max-width: 100% !important;
    height: auto;
    padding-bottom: 3rem;
    margin: 0 auto;
  }

  .about__content p {
    width: 100%;
    font-size: 1.1rem;
  }

  /*Small Screens and Devices (e.g.:MOBILE)*/
  @media screen and (max-width: 480px) {
    .image__container {
      justify-content: flex-start;
      max-width: 100%;
      width: 100%;
      margin-top: 2rem;
    }

    .about__header {
      font-size: 2.6rem;
    }
  }
}
