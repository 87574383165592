.testimonials {
  max-width: 100%;
  height: auto;
}

.testimonial__header {
  font-style: normal;
  font-weight: 400;
  font-size: 6rem;
  flex-direction: column;
}

#test-title-one {
  padding-bottom: .8rem;
}

#test-title-two {
  font-size: 0.5em;
}

.testimonials__container {
  justify-content: space-between;
}

.testimonial {
  max-width: calc(96% / 3);
  background-color: rgba(255, 255, 255, 0.393);
  border-radius: 15px;
}

.testimonial__content {
  padding: 2rem 2rem;
  font-weight: bold;
}

.clients {
  font-size: 2rem;
}

.testimonial__services {
  font-size: 1.5rem;
  font-weight: bolder;
  padding: 18px 0;
}

.testimonial .content {
  font-size: 1rem;
  line-height: 24px;
  font-weight: normal;
}

@media screen and (max-width: 1401px) {
  .testimonial__header {
    font-size: 5rem;
  }
  
}

@media screen and (max-width: 1201px) {
  .testimonials__container {
    display: flex;
    flex-direction: column;
  }

  .testimonial {
    max-width: 85vw;
    margin-bottom: 1rem;
  }

  .testimonial__header {
    font-size: 4rem;
  }
  
  .testimonial__content {
    padding: 1.5rem 2rem;
    font-weight: bold;
    line-height: normal;
  }
  
  .clients {
    font-size: 1.4rem;
  }
  
  .testimonial__services {
    font-size: 1.33rem;
    font-weight: bolder;
    padding: 1rem 0;
  }
  
  .testimonial .content {
    font-size: 1rem;
    line-height: 24px;
  }
}

@media screen and (max-width: 992px) {

  .clients {
    font-size: 1.56rem;
  }
  
  .testimonial__services {
    font-size: 1.33rem;
    font-weight: bolder;
    padding: 18px 0;
  }
  
  .testimonial .content {
    font-size: 1rem;
    line-height: 24px;
  }
}

/*Medium Screens and Devices (e.g.:TABLETS)*/
@media screen and (max-width: 768px) {

  .testimonial__header {
    font-size: 3.7rem;
  }

  .testimonial {
    max-width: 100%;
  }

  .testimonial__content {
    padding: 2rem 1rem;
    aspect-ratio: 2/1;
  }

  .testimonial:not(:first-child) {
    margin-top: 1rem;
  }

  
}

/*Small Screens and Devices (e.g.:MOBILE)*/
@media screen and (max-width: 480px) {
  .testimonials {
    padding: 1rem 0 4rem 0;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .test_title__container {
    width: 100%;
    text-align: center;
  }

  .testimonial__header {
    font-size: 2.6rem;
    text-align: left;
  }

  /*
  .testimonial__header {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1 0 auto;
    font-size: 2.6rem;
    justify-content: center;
    line-height: normal;
  }
  */

  #test-title-one {
    text-align: center;
  }

  #test-title-two {
    text-align: center;
    font-size: 0.8em;
    position: static;
    margin-top: 1rem;
  }

  .testimonials__container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1 0 auto;
  }

  .testimonial {
    max-width: 100%;
    background-color: rgba(255, 255, 255, 0.393);
    border-radius: 15px;
  }

  .testimonial__content {
    padding: 1rem;
    aspect-ratio: 2/1;
  }

  .clients {
    font-size: 1.87rem;
  }

  .testimonial__services {
    font-size: 1.5rem;
    font-weight: bolder;
    padding: 18px 0;
  }

  .testimonial .content {
    font-size: 1rem;
    line-height: 24px;
  }
}
