/* 
=====SITE STYLE SHEET========
  1. Variable CSS Styles
  2. Typography Styles
  3. Global Styles
  4. Page Styles
  5. Media Queries Styles
  
  Note: Components and Routes Pages are styled within their own folder




  /*
========================================
1. Variable CSS Styles
======================================== */
:root {
  --primary-background: #7185F9;
  --secondary-background: ;

  /*FONTS*/
  --primary-txt: #F8F8F8;
  --secondary-txt: ;

  --intern-font: "Inter", sans-serif;
  --gloock-font: "Gloock", serif;

  --normal-text: 1.5rem;
}

/*
======================================== 
2. Typography Styles
======================================== */
@import url("https://fonts.googleapis.com/css2?family=Gloock&family=Inter:wght@300&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Maitree:wght@400;500&display=swap');

p {
  font-family: 'Maitree', serif;
  color: var(--primary-txt);
  font-size: var(--normal-text);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #ffffff;
  font-family: var(--gloock-font);
}

/*
========================================
3. Global Styles
======================================== */

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  scroll-behavior: smooth;
  background-color: var(--primary-background);
  box-sizing: border-box;
}

main {
  max-height: 100vh;
  width: auto;
  height: 100vh;
}

body,
button,
input,
textarea {
  font-family: "Intern", sans-serif;
  font-size: 1rem;
  color: var(--primary-txt);
  background-color: transparent;
}

input {
  color: #000;
}

button {
  transition: all 0.5s;
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: #000;

  -webkit-transition: all 2s ease;
  -moz-transition: all .2s ease;
  -ms-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease;
}

a:hover {
  color: #7185F9; 
}

/*
========================================
4. Layout
======================================== */

.section {
  width: 100vw;
  height: auto;
  padding: 10rem 0 2rem;
}

.container {
  max-width: 90%;
  margin: 0 auto;
}

.grid {
  display: grid;
  gap: 1.5rem;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

/*
========================================
5. Media Queries Styles
======================================== */

/*=========== BREAKPOINTS =============*/
/*Large  Screens and Devices (e.g.:DESKTOPS)*/
@media screen and (max-width: 1200px) {
  * {
    /*
margin: 0;
padding: 0;
box-sizing: border-box;
*/
  }

  html {
    scroll-behavior: smooth;
    background-color: var(--primary-background);
  }

  /*
  body {
    overflow-y: hidden;
    overflow-x: scroll;
  }
*/
  main {
    max-height: 100vh;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  body,
  button,
  input,
  textarea {
    font-family: "Inter", sans-serif;
    font-size: 1rem;
    color: var(--primary-txt);
    background-color: transparent;
  }

  input {
    color: #000;
  }

  button {
    transition: all 0.5s;
    cursor: pointer;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  a {
    text-decoration: none;
    color: #000;
  }

  p{
    font-size: 1rem;
  }

/*
  ========================================
4. Layout
======================================== */

  .section {
    width: 100vw;
    height: auto;
    padding: 7rem 0 2rem;
  }

  .container {
    max-width: 90%;
    margin: 0 auto;
  }

  .grid {
    display: grid;
    gap: .8rem;
  }

  .flex {
    display: flex;
    flex-wrap: wrap;
  }
}

/*Medium Screens and Devices (e.g.:TABLETS)*/
@media screen and (max-width: 768px) {
/*
  body {
    overflow-y: visible;
    overflow-x: visible;
  }
*/
  .container {
    max-width: 85%;
  }

  .container {
    max-width: 90%;
    margin: 0 auto;
  }

  .flex {
    display: flex;
    flex-direction: column;
  }
}

/*Small Screens and Devices (e.g.:MOBILE)*/
@media screen and (max-width: 480px) {
  * {
    margin: 0;
    padding: 0;
  }

  body {
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
  }

  main {
    max-height: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  p {
    font-size: 1rem;
  }

  .section {
    height: 100%;
    padding: 4rem 0 4rem;
  }

  /*
  .section:not(:first-child) {
    padding-top: 2rem;
  }
  */
}
