.contact {
  max-width: 100%;
  max-height: 100;
}

.contact__container {
  font-family: var(--gloock-font);
  max-height: 100%;
  justify-content: space-between;
}

.contact__title__container {
  max-width: 45%;
  flex-direction: column;
}

.title__content {
  font-family: var(--gloock-font);
  font-size: 2rem;
  line-height: 30px;
}

.contact__title {
  font-size: 5rem;
  padding-bottom: .8rem;
}

.secondary-contact__button {
  background-color: transparent;
  padding: 1rem 1.5rem;
  color: #fff;
  border: 3px solid white;
  border-radius: 5px;
  margin-top: 2rem;
  width: 50%;

  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  -ms-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease;
}

.secondary-contact__button:hover,
.secondary-contact__button:hover a {
  color: #7185F9;
  background-color: #fff;
}

.secondary-contact__button a {
  color: #fff;
  font-size: large;
}

.secondary-contact__button a:hover {
  color: #7185F9;
}

.contact__form {
  background-color: rgba(217, 217, 217, 0.5);
  width: 50%;
  border-radius: 15px;
  padding: 2rem;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.contact__content {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
}

.label__title {
  font-size: 1.87rem;
}

.contact input,
textarea {
  border: none;
  background-color: transparent;
  resize: none;
  border-bottom: 1px solid #fff;
  color: var(--primary-txt);
  padding: 0.5rem 0;
  font-family: var(--gloock-font);
  font-size: 1rem;
  font-weight: 100;
}

.contact input::placeholder,
textarea::placeholder {
  color: var(--primary-txt);
  padding: 0.3rem 0;
}

.contact__submit {
  border: none;
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.7);
  color: #000;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-radius: 10px;
  font-family: var(--gloock-font);
  font-size: 2rem;

  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.contact__submit:hover {
  background-color: rgba(255, 255, 255, 1);
}

.contact input:focus {
  outline: none;
}

.contact textarea:focus {
  outline: none;
}

.form__button {
  background-color: transparent;
  padding: 5px 15px;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
}

.form__button:hover {
  background-color: white;
  color: rgba(0, 0, 0, 0.5);
  }

/*Large  Screens and Devices (e.g.:DESKTOPS)*/
@media screen and (max-width: 1201px) {
  .contact__title {
    font-size: 4rem;
  }

  .title__content {
    font-size: 1.2rem;
  }

  .contact__form {
    padding: 1rem;
    max-height: 100%;
  }

  .label__title {
    font-size: 1.3rem;
  }

  .secondary-contact__button {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .secondary-contact__button a {
    font-size: smaller;
  }
}

/*Medium Screens and Devices (e.g.:TABLETS)*/
@media screen and (max-width: 768px) {
  .contact {
    max-width: 100%;
    max-height: 100%;
  }

  .contact__container {
    font-family: var(--gloock-font);
    height: 100%;
    justify-content: space-between;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .contact__title__container {
    max-width: 100%;
  }

  .contact__form {
    max-width: 100%;
    width: 100%;
    padding: 2rem 3rem;
  }
}

/*Small Screens and Devices (e.g.:MOBILE)*/
@media screen and (max-width: 480px) {
  .contact__container {
    display: flex;
    flex-direction: column;
  }

  .contact__title__container {
    padding-bottom: 1.5rem;
    text-align: center;
  }

  .title__content {
    font-family: var(--gloock-font);
    font-size: 1.5em;
    line-height: 30px;
  }

  .contact__title {
    font-size: 2.6rem;
    display: flex;
    flex-direction: column;
  }

  .contact__form {
    max-width: 100%;
    border-radius: 15px;
    padding: 1rem;
  }

  .contact__content {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0;
  }

  .label__title {
    font-size: 1.5rem;
  }

  .contact input,
  textarea {
    border: none;
    background-color: transparent;
    resize: none;
    border-bottom: 1px solid #fff;
    padding: 0.5rem 0;
    font-size: 1rem;
  }

  .contact input::placeholder,
  textarea::placeholder {
    color: var(--primary-txt);
    padding: 0.3rem 0;
  }

  .contact__submit {
    font-size: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .secondary-contact__button {
    width: 100%;
  }
}
