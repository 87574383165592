.blob__container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  align-items: start;
  justify-content: start;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

#overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #000;
  z-index: 1;
  opacity: 0.2;
}

.blob-c {
  min-height: 100%;
  position: absolute;
  max-width: 100vw;
  filter: blur(100px);
}

.shape-blob {
  background: #C3B1E1;
  height: 1200px;
  width: 1000px;
  border-radius: 40% 50% 30% 40%;
  animation: transform 23s ease-in-out infinite both alternate,
    movement_one 17s ease-in-out infinite both;
  opacity: 0.7;
  position: absolute;
  left: 70%;
  top: 40%;
  scale: 1.3;
}
.shape-blob.one {
  background: #93E193;
  height: 1200px;
  width: 1089px;
  left: 10px;
  top: 10px;
  transform: rotate(-180deg);
  opacity: 0.7;
  animation: transform 13s ease-in-out infinite both alternate,
    movement_two 25s ease-in-out infinite both;
}

.shape-blob.six {
  background: #7185F9;
  height: 880px;
  width: 1000px;
  left: 160px;
  top: 200px;
  opacity: 1;
  transform: rotate(-180deg);
  animation: transform 10s ease-in-out infinite both alternate,
    movement_two 10s ease-in-out infinite both;
}

.shape-blob.four {
  background: #93E193;
  height: 600px;
  width: 900px;
  left: 350px;
  top: 60px;
  transform: rotate(-180deg);
  opacity: 0.7;
  animation: transform 22s ease-in-out infinite both alternate,
    movement_two 18s ease-in-out infinite both;
}

.shape-blob.five {
  background: #C3B1E1;
  height: 968px;
  width: 689px;
  left: 480px;
  top: 30px;
  transform: rotate(-180deg);
  animation: transform 17s ease-in-out infinite both alternate,
    movement_two 23s ease-in-out infinite both;
}

.shape-blob.two {
  background: #93E193;
  height: 700px;
  width: 1250px;
  left: 0px;
  top: 0px;
  transform: rotate(-180deg);
  opacity: 0.7;
  animation: transform 15s ease-in-out infinite both alternate,
    movement_two 15s ease-in-out infinite both;
}

.shape-blob.three {
  background: #7185F9;
  opacity: 1;
  height: 08px;
  width: 809px;
  left: 800px;
  top: 30px;
  transform: rotate(-180deg);
  animation: transform 12s ease-in-out infinite both alternate,
    movement_two 28s ease-in-out infinite both;
}

@keyframes transform {
  0%,
  100% {
    border-radius: 33% 67% 70% 30% / 30% 40% 70% 70%;
  }
  20% {
    border-radius: 37% 63% 51% 49% / 37% 35% 35% 63%;
  }
  40% {
    border-radius: 36% 64% 64% 36% / 64% 48% 52% 26%;
  }
  60% {
    border-radius: 37% 63% 51% 49% / 30% 30% 70% 73%;
  }
  80% {
    border-radius: 40% 60% 42% 58% / 51% 51% 49% 59%;
  }
}

@keyframes movement_one {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: translate(50%, 20%) rotateY(10deg) scale(1);
  }
}

@keyframes movement_two {
  0%,
  500% {
    transform: none;
  }
  50% {
    transform: translate(50%, 20%) rotate(-200deg) scale(1.3);
  }
}

@media screen and (max-width: 1200px) {
  .blob__container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    align-items: start;
    justify-content: start;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

@media screen and (max-width: 768px) {
}

/*Small Screens and Devices (e.g.:MOBILE)*/
@media screen and (max-width: 480px) {
  .blob__container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    align-items: start;
    justify-content: start;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .blob-c {
    min-height: 100%;
    position: absolute;
    max-width: 100vw;
    filter: blur(40px);
  }

  .shape-blob {
    background: #faf9f6;
    height: 1200px;
    width: 1000px;
    border-radius: 40% 50% 30% 40%;
    animation: transform 18s ease-in-out infinite both alternate,
      movement_one 12s ease-in-out infinite both;
    opacity: 0.7;
    position: absolute;
    left: 70%;
    top: 40%;
  }
}
