.header {
  width: 100%;
  position: fixed;
  padding: 1rem 0;
  z-index: 12;
  backdrop-filter: blur(40px);
}

.header__container {
  margin-bottom: 1rem;
  justify-items: end;
  border-bottom: 0.2rem solid var(--primary-txt);
}

.contact-link {
  max-width: 20vw;
  font-weight: 400;
  color: var(--primary-txt);
  margin-bottom: 1rem;
}

.contact-link .contact__link {
  line-height: 30px;
}

.mobile__message {
  display: none;
}

.logo-container {
  position: relative;
  display: flex;
  float: left;
  justify-content: space-between;
  width: 90vw;
  margin-top: -0.5rem;
  
}

.logo-container img {
  position: absolute;
}

.logo-container .logo-part-1 {
  width: 6rem;
}

.logo-container .logo-part-2 {
  width: 2.2rem;
  margin-top: 1.85rem;
  margin-left: 1.85rem; 
}

.logo-part-1 {
  animation: rotate 15s infinite linear;
}

.logo-part-2 {
  animation: pulsate 5s infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulsate {
  0% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1.2);
  }
}


@media screen and (max-width: 1200px) {
  .header {
    position: fixed;
  }

  .contact-link {
    max-width: 15rem;
    color: var(--primary-txt);
    font-size: 1rem;
  }

  .logo-container {
    margin-top: -1.5rem;
    justify-content: flex-start;
    width: 102%;
  }

  .logo-container .logo-part-1 {
    margin-top: 0.6rem;
    margin-left: 0.6rem;
    width: 4rem;
  }

  .logo-container .logo-part-2 {
    width: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .header {
    width: 100%;
    position: fixed;
    padding: 1rem 1rem;
  }

  .header__container {
    display: none;
  }

  .mobile__message {
    display: flex;
    font-size: 3rem;
    flex-direction: row-reverse;
    color: #fff;
    margin-bottom: 1rem;
    justify-items: end;
    border-bottom: none;
  }
  .uil-envelope {
    color: #fff;
  }

  .logo-container {
    justify-content: flex-start;
    margin-left: -1rem;
  }

  .logo-container img {
    transform: scale(0.6);
  }
}

/*Small Screens and Devices (e.g.:MOBILE)*/
@media screen and (max-width: 480px) {
  .header {
    width: 100%;
    position: fixed;
    padding: 0.5rem 1rem;
  }
  .mobile__message {
    font-size: 2rem;
  }

  .logo-container {
    margin-top: -1rem;
  }

  .logo-container .logo-part-1 {
    width: 3.5rem;
    margin-top: 1rem;
    margin-left: 1rem;
  }

  .logo-container .logo-part-2 {
    width: 2.3rem;
    margin-top: 1.6rem;
    margin-left: 1.6rem;
  }

  .logo-container img {
    transform: scale(0.6);
  }

  @keyframes pulsate {
    0% {
      transform: scale(0.7);
    }
    50% {
      transform: scale(0.4);
    }
    100% {
      transform: scale(0.7);
    }
  }
}
