.home {
  max-width: 100%;
  padding-top: 15rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.home__container {
  display: flex;
}

.home_title__container {
  width: 100%;
}

.home__header {
  font-style: normal;
  font-weight: 400;
  font-size: 5rem;
  line-height: normal;
  display: flex;
  flex-wrap: wrap;
}

#home-title-second {
  font-size: 2rem;
  display: flex;
  flex-direction: center;
  align-items: center;
  padding-bottom: 1rem;
}

.home__name {
  font-weight: bold;
}

.home__content {
  justify-content: space-between;
  font-weight: bold;
}

.home__quote {
  width: 100%;
  font-size: 1.5em;
  font-weight: lighter;
  line-height: 36px;
}

.home__quote.word_blurb {
  background-color: rgba(255, 255, 255, 0.393);
  border-radius: 50%;
  padding: 4rem;
  max-width: 325px;
  min-width: 200px;
  text-align: center;
  margin-top: -2rem;
  margin-right: 11rem;
  transform: scale(0.9);
}

.home__column-one {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.home__column-two {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.home__image-container {
  margin-top: 2rem;
  display: flex;
  justify-content: end;
}

.home__column-two .home__image {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.home__column-two img {
  width: 35%;
  border-radius: 100%;
  margin-left: 2.5rem;
}

.home__column-two img:nth-child(1) {
  margin-top: -4rem;
  width: 45%;
  height: 100%;
  transform: scale(0.9);
  margin-right: -1rem;
}

.home__column-two img:nth-child(2) {
  margin-top: 0rem;
  width: 45%;
  height: 100%;
}

@media screen and (max-width: 1201px) {
  .home__container {
    flex-direction: column;
  }
  .home__column-one {
    width: 100%;
  }
  .home__column-two {
    width: 100%;
    margin-top: 10vh;
    margin-left: -1rem;
  }
  .home__column-two .home__image-container {
    justify-content: flex-start;
    margin-top: 0;
  }
  .home__column-two img:nth-child(1) {
    margin-top: 0;
    transform: scale(1);
    margin-right: 0;
  }
  .home__quote.word_blurb {
    margin: 0 auto;
  }

  .home__header {
    grid-template-columns: 1fr;
    font-size: 5rem;
    line-height: normal;
    display: grid;
  }

  #home-title-second {
    font-size: 1.5rem;
    order: 3;
    margin-left: 0rem;
  }

  .home__name {
    padding: 0 0 1rem 0;
  }
}

@media screen and (max-width: 768px) {
  .home {
    padding: 7rem 0rem;
  }
  .home__header {
    font-size: 3.8rem;
  }

  .home__container {
    flex-direction: column;
  }

  .home__quote {
    width: 100%;
  }

  .home__quote:not(:first-child) {
    margin-top: 2rem;
  }

  .home__column-one {
    width: 100%;
  }
  
  .home__column-two {
    width: 100%;
    margin-left: 0;
  }

  .home__column-two .home__image-container img:nth-child(1) {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
  }

  .home__column-two .home__image-container img:nth-child(2) {
    display: none;
  }

  .home__column-two .home__image-container {
    margin-top: 3.5rem;
  }

  .home__column-two .home__quote.word_blurb {
    display: none;
  }
}

/*Small Screens and Devices (e.g.:MOBILE)*/
@media screen and (max-width: 480px) {
  .home {
    height: 100%;
  }

  .home__header {
    font-size: 2.6rem;
  }
  
  #home-title-second {
    font-size: 1rem;
  }
}
