.slider {
  position: relative;
  padding: 0 5rem; 

}

.image {
  max-width: 600px;
  height: 700px;
  border-radius: 10px;
}

.slide {
  opacity: 0;
  filter: blur(40px);
  transition: all 4s ease;
}

.slide.active {
  opacity: 1;
  filter: blur(0);
  transition: all 4s ease;
}

@media only screen and (min-width: 375px) and (max-width: 845px) {
  .image {
    width: 100%;
    height: auto;
  }

  .slider {
    padding: 0;
  }
}
