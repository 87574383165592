.navigation {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 25%;
  z-index: 4;
}

.navigation ul > li {
  list-style: none;
}

.nav-link {
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin: 2rem 2rem;
  background-color: transparent;
  border: 1px solid var(--primary-txt);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  transition: background-color 0.4s ease;
}

.nav-link:hover {
  background-color: #fff;
}

#active {
  background-color: #fff;
}

#active:nth-child(-n + 5) {
  background-color: #fff;
}

@media screen and (max-width: 1200px) {
  .navigation {
    max-width: 100%;
    display: flex;
    position: fixed;
    right: 0.8rem;
    top: 25%;
    z-index: 4;
  }
}

/*Medium Screens and Devices (e.g.:TABLETS)*/
@media screen and (max-width: 768px) {
  .navigation {
    max-width: 100%;
    display: flex;
    position: fixed;
    right: 0rem;
    top: 25%;
    z-index: 4;
  }
}

/*Small Screens and Devices (e.g.:MOBILE)*/
@media screen and (max-width: 480px) {
  .navigation {
    max-width: 100%;
    display: none;
    position: fixed;
    right: 0.8rem;
    top: 25%;
    z-index: 4;
  }

  .nav-link {
    cursor: pointer;
    height: 1rem;
    width: 1rem;
    margin: 1.8rem 0.3rem;
    background-color: transparent;
    border: 1px solid var(--primary-txt);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    transition: background-color 0.4s ease;
  }
}
